import React, { useCallback, useRef, useState, useEffect, useMemo } from "react";
import {
	Formik
} from "formik";
import {
	useSelector,
	useDispatch
} from "react-redux";
import {
	FormattedHTMLMessage,
	FormattedMessage,
	useIntl
} from "react-intl";
import clsx from "clsx";
import {
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	makeStyles,
	Tooltip,
	Dialog,
	DialogTitle,
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	DialogContent
} from "@material-ui/core";
import {
	Alert
} from "reactstrap";
import MuiPhoneNumber from "material-ui-phone-number";
import { useHistory, Prompt } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { parse } from "query-string";

import ImageIcon from './image-icon.png';
import Input from "../../../common/Input";
import useUserRestriction from "../../../common/hooks/user-restriction";
import IOSSwitch from '../../../common/IOSSwitch'
import {
	updateProfile,
	flagNames,
	updatePassword,
	updateChatService,
	updateDownloadFormat,
	updateSettings,
	updateTwoFactorAuthService,
	addTwoFactorAuthEmail,
	removeTwoFactorAuthEmail,
} from "../../../store/modules/actions/auth.actions";
import { DangerBlocksIcon, WarningBlocksIcon } from "../../../common/icons";
import Confirm from "../../../common/modals/confirm";
import { dataURLtoFile, doScrolling, isValidEmail, sanitizeFile } from "../../../../_metronic";
import { setUpgradeDialog } from "../../../store/modules/actions/app.actions";
import CropModal from "../../../common/modals/crop-modal";
import { getFlags } from "../../../store/modules/selectors/common.selector";
import { getFileRenameSeparator, getUser } from "../../../store/modules/selectors/account.selector";
import { CloseOutlined, DoneAll } from "@material-ui/icons";

const useStyles = makeStyles((theme) => {
	return {
		logoContainer: {
			background: "rgba(224, 231, 255, 0.2)",
			borderRadius: "10px",
			height: "200px",
			maxHeight: "200px",

			"& img": {
				borderRadius: "10px",
			}
		},
		emptyLogoContainer: {
			border: `2px dashed ${theme.palette.extraColors.blueBorder}`,
		},
		emptyLogoMessage: {
			color: theme.palette.extraColors.grey,
			fontSize: "13px",
		},
		logoActionsContainer: {
			top: "10px",
			right: "10px"
		},
		logoAction: {
			cursor: "pointer",
			color: theme.palette.primary.main,
			background: "rgba(255, 255, 255, 0.49)",
			border: `1px solid ${theme.palette.extraColors.blueBorder}`,
			height: "24px",
			width: "24px",
			borderRadius: "12px"
		},
		logoPreview: {
			objectFit: "contain"
		},
		fieldSubtitle: {
			fontSize: 13,
			color: "#C3C9D5"
		},
		customCharacterInput: {
			maxWidth: 55,

			"& .MuiInputBase-input": {
				height: "22px !important",
				padding: "5px 16px",
				textAlign: "center",
			}
		},
		markCompletedModalIcon: {
			'& svg': {
				fontSize: 54,
			}
		},
	}
});

function Profile() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const intl = useIntl();
	const history = useHistory();
	const CONTAINER_ID = "profile-container";
	const nextLocation = useRef(null);

	const flags = useSelector(getFlags);
	const user = useSelector(getUser);
	const fileRenameSeparator = useSelector(getFileRenameSeparator);

	const {
		goTo,
	} = parse(history.location.search);
	const [showCropModal, setCropModalStatus] = useState(false);
	const [restrictions, isInReadOnlyMode] = useUserRestriction();

	const [logoUrl, setLogoUrl] = useState('');
	const [logo, setLogo] = useState({
		value: '',
		error: '',
	});
	const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
	const [showUnsavedWarningModal, setUnsavedChangesWarningStatus] = useState(false);
	const [showTwoFactorAuthOffConfirmModal, setTwoFactorAuthOffConfirmStatus] = useState(false);
	const [twoFactorAuthEmailToRemove, setTwoFactorAuthEmailToRemove] = useState(null);
	const [twoFactorAuthEmailAddSuccess, setTwoFactorAuthEmailAddSuccess] = useState(false);
	const [twoFactorAuthAddEmail, setTwoFactorAuthAddEmail] = useState(null);

	const twoFactorAuthEmails = useMemo(() => {
		const mfaConfig = user.settings.mfaConfig;

		if (!mfaConfig) {
			return [];
		}

		const currentAccountEmail = user.email;
		const additionalEmails = mfaConfig.map(({ email, status }) => ({
			email,
			status,
		}));

		return [
			{
				email: currentAccountEmail,
				status: 'VERIFIED',
			},
			...additionalEmails
		];
	}, [user]);

	const isMfaEnabled = useMemo(() => user.settings.mfaEnabled, [user]);

	useEffect(() => {
		setLogoUrl(user.companyLogo);
	}, [user]);

	useEffect(() => {
		if (!goTo) return;

		const handle = setInterval(() => {
			const containerElement = document.getElementById(CONTAINER_ID);

			if (!containerElement) return;

			let scrollToElement = null;

			scrollToElement = document.getElementById(goTo);

			if (scrollToElement) {
				doScrolling(`#${goTo}`);
				clearInterval(handle);
			}
		}, 250);

		return () => clearInterval(handle);
	}, [goTo, history]);

	const onDrop = useCallback(async (acceptedFiles) => {
		const selectedFile = await sanitizeFile(acceptedFiles[0]);
		const type = selectedFile.name.split('.').pop();
		const isImageFile = [/png$/i, /jpg$/i, /jpeg$/i].some((imageType) => imageType.test(type));

		if (isInReadOnlyMode || !restrictions.features.BRANDED_INVITES || !restrictions.features.BRANDED_CLIENT_PORTAL) {
			dispatch(setUpgradeDialog("GENERAL.UPGRADE_MESSAGE"));
			return;
		}

		if (isImageFile) {
			setLogo({
				value: selectedFile,
				error: ''
			});
			setCropModalStatus(true);
		}
		else {
			setLogo({
				value: null,
				error: intl.formatMessage({ id: "CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.INVALID_FILE" })
			});
		}
	}, [
		intl,
		restrictions,
		isInReadOnlyMode,
		dispatch,
	]);

	const hideCropModal = useCallback((croppedImageUrl) => {
		setLogoUrl(croppedImageUrl);
		setCropModalStatus(false);

		if (!hasUnsavedChanges) {
			setUnsavedChanges(true);
		}
	}, [hasUnsavedChanges]);

	const handleBlockedNavigation = useCallback((newLocation) => {
		if (hasUnsavedChanges) {
			nextLocation.current = newLocation;
			setUnsavedChangesWarningStatus(true);
			return false;
		}

		return true;
	}, [hasUnsavedChanges]);

	const updateCompanyProfile = useCallback(async (values) => {
		let file = null;

		if (logoUrl && logoUrl.startsWith("data")) {
			file = dataURLtoFile(logoUrl);
		}

		await dispatch(
			updateProfile(
				values.firstName,
				values.lastName,
				values.company,
				values.phone,
				file || logoUrl || ""
			)
		);

		setUnsavedChanges(false);
	}, [dispatch, logoUrl]);

	const handleUnsavedModalClose = useCallback(async (shouldSave, values) => {
		if (shouldSave === null) {
			setUnsavedChangesWarningStatus(false);
		}
		else {
			if (shouldSave) {
				await updateCompanyProfile(values);
			}

			history.push(nextLocation.current.pathname);
		}
	}, [history, updateCompanyProfile]);

	const handleValidateCompanyProfile = useCallback((values) => {
		const errors = {};

		setUnsavedChanges(true);

		if (!values.firstName) {
			errors.firstName = intl.formatMessage({
				id: "AUTH.VALIDATION.REQUIRED_FIELD"
			});
		}
		if (!values.lastName) {
			errors.lastName = intl.formatMessage({
				id: "AUTH.VALIDATION.REQUIRED_FIELD"
			});
		}

		return errors;
	}, [intl]);

	const handleTwoFactorAuthToggle = useCallback(async (e) => {
		if (flags.loading[flagNames.UPDATE_CHAT_SERVICE]) {
			return; 
		}

		setTwoFactorAuthOffConfirmStatus(true);
	}, [flags.loading]);

	const handleTwoFactorAuthOffConfirm = useCallback(async ({ password }) => {
		await dispatch(
			updateTwoFactorAuthService(!isMfaEnabled, password)
		);

		setTwoFactorAuthOffConfirmStatus(false);
	}, [dispatch, isMfaEnabled]);

	const handleTwoFactorAuthEmailRemoveConfirm = useCallback(async (values) => {
		const email = values.email;
		const password = values.password;

		if (!email || !password) {
			return;
		}

		await dispatch(
			removeTwoFactorAuthEmail(email, password)
		);

		setTwoFactorAuthEmailToRemove(null);
	}, [dispatch]);

	const handleValidateAddNewEmail = useCallback((values) => {
		const errors = {};

		if (!values.email) {
			errors.email = intl.formatMessage({
				id: "TWO_FACTOR_AUTH.VALIDATION.REQUIRED_FIELD"
			});
		} else if (!isValidEmail(values.email)) {
			errors.email = intl.formatMessage({
				id: "PROVIDER.EMAIL.VALIDATION.INVALID"
			});
		}

		if (!values.password) {
			errors.password = intl.formatMessage({
				id: "TWO_FACTOR_AUTH.VALIDATION.REQUIRED_FIELD"
			});
		}

		console.log(errors);
		return errors;
	}, [intl]);

	const addNewEmail = useCallback(async (values, { resetForm }) => {
		const email = values.email;
		const password = values.password;

		if (!email || !password) {
			return;
		}

		setTwoFactorAuthEmailAddSuccess(false);

		await dispatch(
			addTwoFactorAuthEmail(email, password)
		);
		
		setTwoFactorAuthAddEmail(false);
		setTwoFactorAuthEmailAddSuccess(true);

		resetForm();
	}, [dispatch]);

	const handleFileRenameSeparatorChange = useCallback((fileRenameSeparator) => {
		if (flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR]) {
			return;
		}

		dispatch(
			updateSettings({
				fileRenameSeparator,
			}),
		);
	}, [dispatch, flags.loading]);

	const validateTurnOffTwoFactorAuth = useCallback((values) => {
		const errors = {};

		if (!values.password) {
			errors.password = intl.formatMessage({
				id: "AUTH.VALIDATION.REQUIRED_FIELD"
			});
		}

		return errors;
	}, [intl]);

	// it's not set to nothing or space which has their own separate options in the UI
	const [isCustomFileRenameCharacterSelected, setIsCustomFileRenameCharacterSelected] = useState(fileRenameSeparator !== '' && fileRenameSeparator !== ' ');

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: false });

	if (!restrictions) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand kt-spinner--center" />
			</div>
		);
	}

	return (
		<div id={CONTAINER_ID} className="pb-20px">
			<div className="px-20px bg-white rounded pb-20px">
				<div className="f-20px pt-20px text-dark">
					<FormattedMessage id="PROFILE.TITLE" />
				</div>

				<div className="mt-25px">
					<Formik
						initialValues={{
							...user
						}}
						validate={handleValidateCompanyProfile}
						onSubmit={updateCompanyProfile}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit} noValidate autoComplete="off">
								<fieldset disabled={isInReadOnlyMode}>
									<div className="row">
										<Input
											labelId="AUTH.INPUT.FIRST_NAME"
											containerClass="col-md-4 col-12 mb-20px"
											variant="outlined"
											fullWidth
											name="firstName"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.firstName}
											helperText={touched.firstName && errors.firstName}
											error={Boolean(touched.firstName && errors.firstName)}
										/>

										<Input
											labelId="AUTH.INPUT.LAST_NAME"
											containerClass="col-md-4 col-12 mb-20px"
											variant="outlined"
											fullWidth
											name="lastName"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.lastName}
											helperText={touched.lastName && errors.lastName}
											error={Boolean(touched.lastName && errors.lastName)}
										/>

										<Input
											labelId="AUTH.INPUT.EMAIL"
											containerClass="col-md-4 col-12 mb-20px"
											variant="outlined"
											fullWidth
											disabled
											name="email"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.email}
											helperText={touched.email && errors.email}
											error={Boolean(touched.email && errors.email)}
										/>

										<div className="col-md-4 col-12">
											<div className="dark-custom-input input-phone form-group">
												<FormLabel className="mb-10">
													<FormattedMessage id="AUTH.INPUT.PHONE" />
												</FormLabel>
												<MuiPhoneNumber
													enableLongNumbers
													disableAreaCodes
													defaultCountry="au"
													variant="outlined"
													type="text"
													fullWidth
													name="phone"
													onBlur={handleBlur}
													onChange={(v) => setFieldValue(`phone`, v)}
													value={values.phone}
													helperText={touched.phone && errors.phone}
													error={Boolean(touched.phone && errors.phone)}
												/>
											</div>
										</div>

										<Input
											labelId="AUTH.INPUT.COMPANY_NAME"
											containerClass="col-md-4 col-12 mb-20px"
											variant="outlined"
											fullWidth
											name="company"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.company}
											helperText={touched.company && errors.company}
											error={Boolean(touched.company && errors.company)}
										/>

										<div className="col-md-4 col-12"></div>

										<div className="col-md-4 col-12">
											<div className="dark-input-label mb-2">
												<FormattedMessage id="AUTH.COMPANY_LOGO" />
											</div>
											<div className="d-flex justify-content-center justify-content-md-left">
												{
													logoUrl ? (
														<div
															className={
																clsx(
																	classes.logoContainer,
																	"flex-grow-1 d-flex flex-column align-items-center justify-content-center position-relative border",
																)
															}
														>
															<img
																alt="logo"
																src={logoUrl}
																className={clsx(classes.logoPreview, "h-100 w-100")}
															/>

															<div className={clsx("position-absolute d-flex", classes.logoActionsContainer)}>
																<div
																	className={clsx(classes.logoAction, "mr-2 d-flex justify-content-center align-items-center position-relative overflow-hidden")}
																>
																	<input
																		title={intl.formatMessage({ id: "CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.UPLOAD" })}
																		type="file"
																		className="cursor-pointer"
																		accept="image/*"
																		style={{
																			position: 'absolute',
																			top: 0,
																			bottom: 0,
																			left: 0,
																			right: 0,
																			opacity: 0
																		}}
																		onChange={(e) => onDrop(e.target.files)}
																	/>

																	<div>
																		<span className="fas fa-upload"></span>
																	</div>
																</div>
																<div
																	className={clsx(classes.logoAction, "d-flex justify-content-center align-items-center")}
																	title={intl.formatMessage({ id: "CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.DELETE" })}
																>
																	<div onClick={() => setLogoUrl("")}>
																		<span className="fas fa-trash-alt"></span>
																	</div>
																</div>
															</div>
														</div>
													) : (
														<div
															{...getRootProps()}
															className={
																clsx(
																	classes.logoContainer,
																	classes.emptyLogoContainer,
																	"flex-grow-1 d-flex flex-column align-items-center justify-content-center",
																	{ 'border-primary': isDragActive }
																)
															}
														>
															<div className="mb-25px">
																<img
																	alt="icon"
																	src={ImageIcon}
																	height="50"
																	width="50"
																/>
															</div>
															<div className={classes.emptyLogoMessage}>
																<FormattedMessage id="CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.EMPTY_STATE.MESSAGE" />
															</div>

															<input {...getInputProps()} />
														</div>
													)
												}
											</div>
										</div>

										{
											!!flags.error[flagNames.USER_UPDATE] && (
												<Alert
													color="danger"
													className="text-center mb-25"
												>
													{flags.error[flagNames.USER_UPDATE]}
												</Alert>
											)
										}
									</div>

									<div className="pt-20px d-flex justify-content-md-start justify-content-center">
										<button
											type="submit"
											disabled={flags.loading[flagNames.USER_UPDATE]}
											className={`btn btn-primary btn-lg btn-elevate py-3 ${clsx(
												{
													"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[flagNames.USER_UPDATE]
												}
											)}`}
										>
											<FormattedMessage id="PROFILE.BUTTON" />
										</button>
									</div>
								</fieldset>
								{
									showCropModal && (
										<CropModal
											file={logo.value}
											titleLabelId={'CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.CROP'}
											handleClose={(croppedImageUrl) => hideCropModal(croppedImageUrl)}
										/>
									)
								}

								<Prompt
									when={hasUnsavedChanges && !nextLocation.current}
									message={handleBlockedNavigation}
								/>

								{
									showUnsavedWarningModal && (
										<Confirm
											open
											icon={<WarningBlocksIcon />}
											variant="warning"
											handleClose={(shouldSave) => handleUnsavedModalClose(shouldSave, values)}
											title="CUSTOMIZATION.COMMUNICATIONS.UNSAVED_WARNING.TITLE"
											message="CUSTOMIZATION.COMMUNICATIONS.UNSAVED_WARNING.MESSAGE"
											submitButtonText="GENERAL.SAVE"
											cancelButtonText="GENERAL.DISCARD"
											loading={flags.loading[flagNames.USER_UPDATE]}
											error={flags.error[flagNames.USER_UPDATE]}
										/>
									)
								}
							</form>
						)}
					</Formik>
				</div>
			</div>

			<div className="p-20px mt-25px bg-white rounded">
				<div className="d-flex align-items-center">
					<div className="f-20px mr-15px text-dark">
						<FormattedMessage id="PROFILE.FIELD.CHAT_SERVICE" />
					</div>
					<div>
						<IOSSwitch
							rootClass={clsx("my-0 mr-2 ml-0", { "opacity-30": flags.loading[flagNames.UPDATE_CHAT_SERVICE] })}
							enabledTrackClass="bg-primary"
							checked={user.chatService}
							onChange={() => !flags.loading[flagNames.UPDATE_CHAT_SERVICE] && dispatch(updateChatService(!user.chatService))}
						/>
					</div>
				</div>
				<div className="mt-20px f-14px text-dark fw-300">
					<FormattedMessage id="PROFILE.FIELD.CHAT_SERVICE.HINT" />
				</div>
				{
					!!flags.error[flagNames.UPDATE_CHAT_SERVICE] && (
						<div className="mt-15px d-flex justify-content-center">
							<Alert
								color="danger"
								className="text-center mb-25"
							>
								{flags.error[flagNames.UPDATE_CHAT_SERVICE]}
							</Alert>
						</div>
					)
				}
			</div>

			<div className="p-20px mt-25px bg-white rounded">
				<div className="d-flex flex-column">
					<div className="f-20px text-dark mb-10px">
						<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT" />
					</div>
					<div>
						<RadioGroup
							name="downloadFormat"
							value={user.downloadFormat}
							onChange={(e) => {
								const newValue = e.target.value;

								if (!flags.loading[flagNames.UPDATE_DOWNLOAD_FORMAT] && newValue !== user.downloadFormat) {
									dispatch(updateDownloadFormat(e.target.value));
								}
							}}
						>
							<FormControlLabel
								className="mb-0"
								disabled={flags.loading[flagNames.UPDATE_DOWNLOAD_FORMAT]}
								value="FLAT"
								control={<Radio color="primary" />}
								label={
									<div className="d-flex flex-column align-items-start flex-sm-row align-items-sm-center">
										<div className="mr-2 f-16px text-dark">
											<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT.FLAT.TITLE" />
										</div>
										<div className="text-dark f-14px fw-300">
											<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT.FLAT.SUBTITLE" />
										</div>
									</div>
								}
							/>
							<FormControlLabel
								className="mb-0"
								disabled={flags.loading[flagNames.UPDATE_DOWNLOAD_FORMAT]}
								value="HIERARCHICAL"
								control={<Radio color="primary" />}
								label={
									<div className="d-flex flex-column align-items-start flex-sm-row align-items-sm-center">
										<div className="mr-2 f-16px text-dark">
											<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT.HIERARCHICAL.TITLE" />
										</div>
										<div className="text-dark f-14px fw-300">
											<FormattedMessage id="PROFILE.FIELD.DOWNLOAD_FORMAT.HIERARCHICAL.SUBTITLE" />
										</div>
									</div>
								}
							/>
						</RadioGroup>
					</div>
				</div>
			</div>

			{
				process.env.REACT_APP_ENABLED_FILE_RENAME_SEPARATOR !== "false" && (
					<div
						className={clsx("p-20px mt-25px bg-white rounded", { "border-primary-blink": goTo === "file-rename-separator-setting" })}
						id="file-rename-separator-setting"
					>
						<div className="d-flex flex-column">
							<div className="f-20px text-dark mb-15px">
								<FormattedMessage id="PROFILE.FIELD.FILE_RENAME_SEPARATOR" />
							</div>
							<div>
								<div className="d-flex">
									<div className="mr-10px">
										<FormControlLabel
											className="mb-0 mr-0"
											onClick={() => setIsCustomFileRenameCharacterSelected(true)}
											disabled={flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR]}
											control={<Radio color="primary" checked={isCustomFileRenameCharacterSelected} />}
											classes={{
												label: "f-16px text-dark",
											}}
											label={intl.formatMessage({ id: "PROFILE.FIELD.FILE_RENAME_SEPARATOR.SPECIFIC_CHARACTER.TITLE" })}
										/>
									</div>

									<div>
										<Input
											type="text"
											className={classes.customCharacterInput}
											variant="outlined"
											onFocus={(e) => e.target.select()}
											onChange={(e) => e.target.value = e.target.value.substring(e.target.value.length - 1)}
											onBlur={(e) => e.target.value && handleFileRenameSeparatorChange(e.target.value)}
											disabled={flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR] || !isCustomFileRenameCharacterSelected}
											defaultValue={fileRenameSeparator}
										/>
									</div>
								</div>

								<div>
									<FormControlLabel
										className="mb-0"
										disabled={flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR]}
										onClick={() => handleFileRenameSeparatorChange(' ')}
										control={<Radio color="primary" checked={!isCustomFileRenameCharacterSelected && fileRenameSeparator === ' '} />}
										label={intl.formatMessage({ id: "PROFILE.FIELD.FILE_RENAME_SEPARATOR.SPACE.TITLE" })}
										classes={{
											label: "f-16px text-dark",
										}}
									/>
								</div>

								<div>
									<FormControlLabel
										className="mb-0"
										disabled={flags.loading[flagNames.UPDATE_FILE_RENAME_SEPARATOR]}
										onClick={() => handleFileRenameSeparatorChange('')}
										control={<Radio color="primary" checked={!isCustomFileRenameCharacterSelected && fileRenameSeparator === ''} />}
										label={intl.formatMessage({ id: "PROFILE.FIELD.FILE_RENAME_SEPARATOR.NOTHING.TITLE" })}
										classes={{
											label: "f-16px text-dark",
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				)
			}

			<div className="p-20px mt-25px bg-white rounded">
				<div className="f-20px text-dark mb-10px">
					<FormattedMessage id="CHANGE_PASSWORD.TITLE" />
				</div>

				<div className="mt-25px">
					<Formik
						initialValues={{
							oldPassword: '',
							newPassword: '',
							confirmNewPassword: '',
						}}
						validate={values => {
							const errors = {};

							if (!values.oldPassword) {
								errors.oldPassword = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD"
								});
							}
							if (!values.newPassword) {
								errors.newPassword = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD"
								});
							}
							if (!values.confirmNewPassword) {
								errors.confirmNewPassword = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD"
								});
							}
							if (values.confirmNewPassword !== values.newPassword) {
								errors.confirmNewPassword = intl.formatMessage({
									id: "CHANGE_PASSWORD.VALIDATION.PASSWORD_MISMATCH"
								});
							}

							console.log(errors);

							return errors;
						}}
						onSubmit={(values) => {
							dispatch(updatePassword(
								values.oldPassword,
								values.newPassword
							));
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit} noValidate autoComplete="off" className="row">
								<Input
									labelId="CHANGE_PASSWORD.INPUT.OLD_PASSWORD"
									containerClass="col-md-4 col-12 mb-20px"
									type="password"
									variant="outlined"
									fullWidth
									name="oldPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.oldPassword}
									helperText={touched.oldPassword && errors.oldPassword}
									error={Boolean(touched.oldPassword && errors.oldPassword)}
								/>

								<Input
									labelId="CHANGE_PASSWORD.INPUT.NEW_PASSWORD"
									containerClass="col-md-4 col-12 mb-20px"
									type="password"
									variant="outlined"
									fullWidth
									name="newPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.newPassword}
									helperText={touched.newPassword && errors.newPassword}
									error={Boolean(touched.newPassword && errors.newPassword)}
								/>

								<Input
									labelId="CHANGE_PASSWORD.INPUT.CONFIRM_NEW_PASSWORD"
									containerClass="col-md-4 col-12 mb-20px"
									type="password"
									variant="outlined"
									fullWidth
									name="confirmNewPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.confirmNewPassword}
									helperText={touched.confirmNewPassword && errors.confirmNewPassword}
									error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
								/>

								{
									!!flags.error[flagNames.UPDATE_PASSWORD] && (
										<div className="col-12 d-flex justify-content-center py-15px">
											<Alert
												color="danger"
												className="text-center mb-25"
											>
												{flags.error[flagNames.UPDATE_PASSWORD]}
											</Alert>
										</div>
									)
								}

								<div className="d-flex justify-content-md-start justify-content-center col-12">
									<button
										type="submit"
										disabled={flags.loading[flagNames.UPDATE_PASSWORD]}
										className={`btn btn-primary btn-lg btn-elevate py-3 ${clsx(
											{
												"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[flagNames.UPDATE_PASSWORD]
											}
										)}`}
									>
										<FormattedMessage id="CHANGE_PASSWORD.BUTTON" />
									</button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>

			<div className="p-20px mt-25px bg-white rounded">
				<div className="d-flex align-items-center mb-10px">
					<div className="f-20px text-dark mr-15px">
						<FormattedMessage id="TWO_FACTOR_AUTH.TITLE" />
					</div>

					<div
						className={clsx("my-0 mr-2 ml-0", { "opacity-30": flags.loading[flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE] })}
						onClick={handleTwoFactorAuthToggle}
					>
						{/* current switch component is not working properly, as it is not controlled by the state */}
						{
							isMfaEnabled ? (
								<IOSSwitch
									enabledTrackClass="bg-primary"
									checked
								/>
							) : (
								<IOSSwitch
									enabledTrackClass="bg-primary"
									checked={false}
								/>
							)
						}
					</div>
				</div>

				<div>
					<FormattedMessage id="TWO_FACTOR_AUTH.HINT" />
				</div>

				{
					!!flags.error[flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE] && (
						<div className="d-flex mt-10px">
							<Alert
								color="danger"
								className="text-center"
							>
								{flags.error[flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE]}
							</Alert>
						</div>
					)
				}

				<div>
					{
						isMfaEnabled && (
							<>
								<div className="col-md-4 col-12 my-10px">
									<button
										type="button"
										disabled={!isMfaEnabled || flags.loading[flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE] || flags.loading[flagNames.ADD_TWO_FACTOR_AUTH_EMAIL]}
										onClick={() => setTwoFactorAuthAddEmail(true)}
										className="btn btn-primary btn-sm"
									>
										<FormattedMessage id="TWO_FACTOR_AUTH.SUBMIT" />
									</button>
								</div>
	
								<div className="d-flex flex-column">
									<div className="mb-5px f-15px">
										<FormattedMessage id="TWO_FACTOR_AUTH.EMAIL_LIST.TITLE" />
									</div>

									{
										!!flags.error[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL] && (
											<div className="d-flex">
												<Alert
													color="danger"
													className="text-center"
												>
													{flags.error[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL]}
												</Alert>
											</div>
										)
									}

									<div>
										{
											twoFactorAuthEmails.map((obj, index) => (
												<div key={index} className="d-flex align-items-center f-15px">
													<div className="mr-5px">
														{`${index + 1}.`}
													</div>

													<div className="mr-5px">
														{obj.email}
													</div>

													<div className="d-flex align-items-center">
														<div className="mr-5px">
															{obj.status === 'VERIFIED' && (
																<Tooltip title={intl.formatMessage({ id: "TWO_FACTOR_AUTH.EMAIL_LIST.STATUS.VERIFIED" })}>
																	<div>
																		<span className="fas fa-check-circle text-success"></span>
																	</div>
																</Tooltip>
															)}

															{obj.status === 'PENDING' && (
																<Tooltip title={intl.formatMessage({ id: "TWO_FACTOR_AUTH.EMAIL_LIST.STATUS.UNVERIFIED" })}>
																	<div>
																		<span className="fas fa-exclamation-circle text-warning"></span>
																	</div>
																</Tooltip>
															)}
														</div>
													</div>

													{
														obj.email !== user.email && (
															<div>
																{
																	flags.loading[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL] && flags.loading[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL] === obj.email ? (
																		<div>
																			<div className="kt-spinner kt-spinner--sm kt-spinner--danger" />
																		</div>
																	) : (
																		<div
																			className={clsx("text-decoration-underline cursor-pointer", {
																				"text-danger": !flags.loading[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL],
																				"text-disabled": flags.loading[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL],
																			})}
																			onClick={() => setTwoFactorAuthEmailToRemove(obj.email)}
																		>
																			<FormattedMessage id="TWO_FACTOR_AUTH.EMAIL_LIST.DELETE" />
																		</div>
																	)
																}
															</div>
														)
													}
												</div>
											))
										}
									</div>

									<Dialog open={twoFactorAuthEmailToRemove !== null}>
										<DialogTitle className="p-0">
											<AppBar
												elevation={0}
												position="static"
												classes={{
													root: "rounded-top bg-light"
												}}
											>
												<Toolbar classes={{ root: "pr-1 border-bottom text-dark" }}>
													<div className="flex-grow-1">
														<Typography
															variant="span"
															className="f-16px"
														>
															<FormattedMessage id="TWO_FACTOR_AUTH.REMOVE_EMAIL.TITLE" />
														</Typography>
													</div>
													<div>
														<IconButton onClick={() => setTwoFactorAuthEmailToRemove(null)}>
															<CloseOutlined />
														</IconButton>
													</div>
												</Toolbar>
											</AppBar>
										</DialogTitle>
										<DialogContent>
											<Formik
												initialValues={{
													email: twoFactorAuthEmailToRemove,
													password: "",
												}}
												validate={validateTurnOffTwoFactorAuth}
												onSubmit={handleTwoFactorAuthEmailRemoveConfirm}
											>
												{({
													values,
													errors,
													touched,
													handleChange,
													handleBlur,
													handleSubmit,
												}) => (
													<form onSubmit={handleSubmit} noValidate autoComplete="off">
														<div className="row my-10px">
															<div className="col-12">
																<div className="d-flex flex-column align-items-center">
																	<div className="mx-auto">
																		<DangerBlocksIcon />
																	</div>
																	<div className="d-flex justify-content-center mb-10px p-10px f-15px text-center">
																		<FormattedHTMLMessage id="TWO_FACTOR_AUTH.REMOVE_EMAIL.MESSAGE" values={{ email: twoFactorAuthEmailToRemove }} />
																	</div>
																</div>
																<div className="p-15px">
																	<Input
																		containerClass="mb-25"
																		labelId="AUTH.INPUT.PASSWORD"
																		variant="outlined"
																		type="password"
																		fullWidth
																		name="password"
																		onBlur={handleBlur}
																		onChange={handleChange}
																		value={values.password}
																		helperText={touched.password && errors.password}
																		error={Boolean(touched.password && errors.password)}
																	/>

																	{
																		flags.error[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL] && (
																			<Alert color="danger" className="d-flex justify-content-center mb-25">
																				{flags.error[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL]}
																			</Alert>
																		)
																	}

																	<div>
																		<button
																			id="kt_login_signin_submit"
																			type="button"
																			onClick={handleSubmit}
																			disabled={flags.loading[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL]}
																			className={`btn btn-danger btn-lg btn-block btn-elevate kt-login__btn-danger py-3 ${clsx(
																				{
																					"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL]
																				}
																			)}`}
																		>
																			<FormattedMessage id="TWO_FACTOR_AUTH.REMOVE_EMAIL.SUBMIT" />
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</form>
												)}
											</Formik>
										</DialogContent>
									</Dialog>

									<Dialog open={twoFactorAuthAddEmail}>
										<DialogTitle className="p-0">
											<AppBar
												elevation={0}
												position="static"
												classes={{
													root: "rounded-top bg-light"
												}}
											>
												<Toolbar classes={{ root: "pr-1 border-bottom text-dark" }}>
													<div className="flex-grow-1">
														<Typography
															variant="span"
															className="f-16px"
														>
															<FormattedMessage id="TWO_FACTOR_AUTH.ADD_EMAIL.TITLE" />
														</Typography>
													</div>
													<div>
														<IconButton onClick={() => setTwoFactorAuthEmailToRemove(null)}>
															<CloseOutlined />
														</IconButton>
													</div>
												</Toolbar>
											</AppBar>
										</DialogTitle>
										<DialogContent>
											<Formik
												initialValues={{
													email: "",
													password: "",
												}}
												validate={handleValidateAddNewEmail}
												onSubmit={addNewEmail}
											>
												{({
													values,
													errors,
													touched,
													handleChange,
													handleBlur,
													handleSubmit,
												}) => (
													<form onSubmit={handleSubmit} noValidate autoComplete="off">
														<div className="row my-10px">
															<div className="col-12">
																<div className="d-flex flex-column align-items-center">
																	<div className="d-flex justify-content-center mb-10px p-10px f-15px text-center">
																		<FormattedHTMLMessage id="TWO_FACTOR_AUTH.ADD_EMAIL.MESSAGE" />
																	</div>
																</div>

																<Input
																	containerClass="mb-25"
																	labelId="TWO_FACTOR_AUTH.INPUT.EMAIL"
																	variant="outlined"
																	fullWidth
																	disabled={flags.loading[flagNames.ADD_TWO_FACTOR_AUTH_EMAIL]}
																	name="email"
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.email}
																	helperText={touched.email && errors.email}
																	error={Boolean(touched.email && errors.email)}
																/>

																<Input
																	containerClass="mb-25"
																	labelId="AUTH.INPUT.PASSWORD"
																	variant="outlined"
																	type="password"
																	fullWidth
																	disabled={flags.loading[flagNames.ADD_TWO_FACTOR_AUTH_EMAIL]}
																	name="password"
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.password}
																	helperText={touched.password && errors.password}
																	error={Boolean(touched.password && errors.password)}
																/>

																{
																	flags.error[flagNames.ADD_TWO_FACTOR_AUTH_EMAIL] && (
																		<Alert color="danger" className="d-flex justify-content-center mb-25">
																			{flags.error[flagNames.ADD_TWO_FACTOR_AUTH_EMAIL]}
																		</Alert>
																	)
																}
															</div>

															<div className="col-12 mt-10px">
																<button
																	id="kt_login_signin_submit"
																	type="button"
																	onClick={handleSubmit}
																	disabled={flags.loading[flagNames.ADD_TWO_FACTOR_AUTH_EMAIL]}
																	className={`btn btn-primary btn-lg btn-block btn-elevate kt-login__btn-primary py-3 ${clsx(
																		{
																			"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[flagNames.ADD_TWO_FACTOR_AUTH_EMAIL]
																		}
																	)}`}
																>
																	<FormattedMessage id="TWO_FACTOR_AUTH.ADD_EMAIL.SUBMIT" />
																</button>
															</div>
														</div>
													</form>
												)}
											</Formik>
										</DialogContent>
									</Dialog>

									<Confirm
										open={twoFactorAuthEmailAddSuccess}
										icon={(
											<div className={clsx("text-success", classes.markCompletedModalIcon)}>
												<DoneAll />
											</div>
										)}
										variant="success"
										handleClose={() => setTwoFactorAuthEmailAddSuccess(false)}
										title="TWO_FACTOR_AUTH.ADD_EMAIL.CONFIRM_TITLE"
										message="TWO_FACTOR_AUTH.ADD_EMAIL.CONFIRM_MESSAGE"
										submitButtonText="GENERAL.OK"
									/>
								</div>
							</>
						)
					}

					<Dialog open={showTwoFactorAuthOffConfirmModal}>
						<DialogTitle className="p-0">
							<AppBar
								elevation={0}
								position="static"
								classes={{
									root: "rounded-top bg-light"
								}}
							>
								<Toolbar classes={{ root: "pr-1 border-bottom text-dark" }}>
									<div className="flex-grow-1">
										<Typography
											variant="span"
											className="f-16px"
										>
											<FormattedMessage id={isMfaEnabled ? "TWO_FACTOR_AUTH.OFF_CONFIRM.TITLE" : "TWO_FACTOR_AUTH.ON_CONFIRM.TITLE"} />
										</Typography>
									</div>
									<div>
										<IconButton onClick={() => setTwoFactorAuthOffConfirmStatus(false)}>
											<CloseOutlined />
										</IconButton>
									</div>
								</Toolbar>
							</AppBar>
						</DialogTitle>
						<DialogContent>
							<Formik
								initialValues={{
									password: "",
								}}
								validate={validateTurnOffTwoFactorAuth}
								onSubmit={(values) => handleTwoFactorAuthOffConfirm(values)}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									setFieldValue,
								}) => (
									<form onSubmit={handleSubmit} noValidate autoComplete="off">
										<div className="row my-10px">
											<div className="col-12">
												<div>
													<div className="d-flex justify-content-center mb-10px p-10px f-15px">
														<FormattedMessage id={isMfaEnabled ? "TWO_FACTOR_AUTH.OFF_CONFIRM.MESSAGE" : "TWO_FACTOR_AUTH.ON_CONFIRM.MESSAGE"} />
													</div>
												</div>
												<div className="p-15px">
													<Input
														containerClass="mb-25"
														labelId="AUTH.INPUT.PASSWORD"
														variant="outlined"
														type="password"
														fullWidth
														name="password"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.password}
														helperText={touched.password && errors.password}
														error={Boolean(touched.password && errors.password)}
													/>

													{
														flags.error[flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE] && (
															<Alert color="danger" className="d-flex justify-content-center mb-25">
																{flags.error[flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE]}
															</Alert>
														)
													}

													<div>
														<button
															id="kt_login_signin_submit"
															type="button"
															onClick={handleSubmit}
															disabled={flags.loading[flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE]}
															className={`btn btn-primary btn-lg btn-block btn-elevate kt-login__btn-primary py-3 ${clsx(
																{
																	"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE]
																}
															)}`}
														>
															<FormattedMessage id="AUTH.LOGIN.BUTTON" />
														</button>
													</div>
												</div>
											</div>
										</div>
									</form>
								)}
							</Formik>
						</DialogContent>
					</Dialog>
				</div>
			</div>
		</div>
	);
}

export default Profile;
