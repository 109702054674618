import _ from 'lodash';
import * as AuthCRUD from '../../../crud/auth.crud';
import * as ProviderCRUD from '../../../crud/provider.crud';
import * as SubscriptionCRUD from '../../../crud/subscriptions.crud';
import { setErrorFlag, setLoadingFlag } from './loading-flag.actions';
import { enqueueSnackbar } from './notistack.actions';

const sleep = (duration = 5000, status = 200, data = {}) => new Promise((res) => {
	setTimeout(() => {
		res({
			status,
			data,
		});
	}, duration);
});

export const actionTypes = {
	Login: "[AUTH] LOGIN",
	Logout: "[AUTH] LOGOUT",
	Register: "[AUTH] REGISTER",
	UserRequested: "[AUTH] USER_REQUESTED",
	ResetPassword: "[AUTH] RESET_PASSWORD",
	UserUpdated: "[AUTH] USER_UPDATED",
	UpdateCommunicationsData: "[AUTH] UPDATE_COMMUNICATIONS_DATA",
	UploadAttachmentFile: "[AUTH] UPLOAD_ATTACHMENT_FILE",
	DeleteAttachmentFile: "[AUTH] DELETE_ATTACHMENT_FILE",
	UpdateNotificationSettings: "[AUTH] UPDATE_NOTIFICATION_SETTINGS",
	UpdateUserRestrictions: "[AUTH] UPDATE_USER_RESTRICTIONS",
	UpdateSettings: "[AUTH] UPDATE_SETTINGS",
};

export const flagNames = {
	LOGIN: "[AUTH] LOGIN",
	REGISTER: "[AUTH] REGISTER",
	LOGOUT: "[AUTH] LOGOUT",
	LOGGED_IN_USER: "[AUTH] LOGGED_IN_USER",
	RESET_PASSWORD: "[AUTH] RESET_PASSWORD",
	USER_UPDATE: "[AUTH] USER_UPDATE",
	UPDATE_PASSWORD: "[AUTH] UPDATE_PASSWORD",
	UPDATE_COMMUNICATIONS_DATA: "[AUTH] UPDATE_COMMUNICATIONS_DATA",
	UPLOAD_ATTACHMENT_FILE: "[AUTH] UPLOAD_ATTACHMENT_FILE",
	DELETE_ATTACHMENT_FILE: "[AUTH] DELETE_ATTACHMENT_FILE",
	UPDATE_NOTIFICATION_SETTINGS: "[AUTH] UPDATE_NOTIFICATION_SETTINGS",
	UPDATE_CHAT_SERVICE: "[AUTH] UPDATE_CHAT_SERVICE",
	UPDATE_TWO_FACTOR_AUTH_SERVICE: "[AUTH] UPDATE_TWO_FACTOR_AUTH_SERVICE",
	ADD_TWO_FACTOR_AUTH_EMAIL: "[AUTH] ADD_TWO_FACTOR_AUTH_EMAIL",
	REMOVE_TWO_FACTOR_AUTH_EMAIL: "[AUTH] REMOVE_TWO_FACTOR_AUTH_EMAIL",
	VALIDATE_TWO_FACTOR_AUTH_TOKEN: "[AUTH] VALIDATE_TWO_FACTOR_AUTH_TOKEN",
	UPDATE_DOWNLOAD_FORMAT: "[AUTH] UPDATE_DOWNLOAD_FORMAT",
	UPDATE_FILE_RENAME_SEPARATOR: "[AUTH] UPDATE_FILE_RENAME_SEPARATOR",
	CANCEL_SUBSCRIPTION: "[AUTH] CANCEL_SUBSCRIPTION",
};

export const login = (email, password, twoFactorAuthCode) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.LOGIN, true));

		try {
			const { status, data } = await AuthCRUD.login(email, password, twoFactorAuthCode);

			if (status === 200) {
				if (data.token) {
					dispatch({
						type: actionTypes.Login,
						payload: {
							authToken: data.token,
							user: {
								...data.user,
							},
						}
					});
				}
			}
			else {
				dispatch(setErrorFlag(flagNames.LOGIN, data.error.message));
			}

			dispatch(setLoadingFlag(flagNames.LOGIN, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.LOGIN, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const resetPassword = (userId, token, password) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.RESET_PASSWORD, true));

		try {
			const { status, data } = await AuthCRUD.resetPassword(userId, token, password);

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.RESET_PASSWORD, data.error.message));
			}

			dispatch(setLoadingFlag(flagNames.RESET_PASSWORD, false));
			return Promise.resolve({status, data});
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.RESET_PASSWORD, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const logout = () => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.LOGOUT, true));

		try {
			const { status, data } = await AuthCRUD.logout();

			if (status === 200) {
				dispatch({
					type: actionTypes.Logout,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.LOGOUT, data.message));
			}

			dispatch(setLoadingFlag(flagNames.LOGOUT, false));
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.LOGOUT, errorMessage));
		}
	}
}

export const register = (email, firstName, lastName, password, companyName, phone, referralCode, howFoundOut, howFoundOutSource) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.REGISTER, true));

		try {
			const { status, data } = await AuthCRUD.register(
				email,
				firstName,
				lastName,
				password,
				companyName,
				phone,
				referralCode,
				howFoundOut,
				howFoundOutSource,
			);

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.REGISTER, data.message));
			}

			dispatch(setLoadingFlag(flagNames.REGISTER, false));

			return status === 200 ? Promise.resolve() : Promise.reject(data);
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.REGISTER, errorMessage));

			return Promise.reject(e.response.data);
		}
	}
};

export const requestLoggedInUser = () => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.LOGGED_IN_USER, true));

		try {
			const { status, data } = await AuthCRUD.getUserByToken();

			if (status === 200) {
				dispatch({
					type: actionTypes.UserRequested,
					payload: {
						user: {
							...data,
						},
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.LOGGED_IN_USER, data.message));
			}

			dispatch(setLoadingFlag(flagNames.LOGGED_IN_USER, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.LOGGED_IN_USER, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const updateNotificationSettings = (type, key, field, value) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.UPDATE_NOTIFICATION_SETTINGS, { type, key, field }));

		try {
			const store = getStore();
			const userId = _.get(store, 'auth.user._id', null);
			let notificationSettings = _.get(store, 'auth.user.notificationSettings');

			if (!notificationSettings) {
				const message = "Notification settings not found";

				dispatch(enqueueSnackbar({
					message,
					options: {
						key: new Date().getTime() + Math.random(),
						variant: 'error',
					},
				}));
				dispatch(setErrorFlag(flagNames.UPDATE_NOTIFICATION_SETTINGS, message));
				return;
			}

			notificationSettings = _.cloneDeep(notificationSettings);

			notificationSettings.forEach((setting) => {
				if (setting.type === type && setting.key === key) {
					setting[field] = value;
				}
			});

			const { status, data } = await AuthCRUD.updateNotificationSettings(
				userId,
				notificationSettings
			);

			if (status === 200) {
				dispatch({
					type: actionTypes.UserUpdated,
					payload: {
						user: {
							...data
						},
					}
				});
			}
			else {
				dispatch(enqueueSnackbar({
					message: data.message,
					options: {
						key: new Date().getTime() + Math.random(),
						variant: 'error',
					},
				}));
				dispatch(setErrorFlag(flagNames.UPDATE_NOTIFICATION_SETTINGS, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_NOTIFICATION_SETTINGS, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(enqueueSnackbar({
				message: errorMessage,
				options: {
					key: new Date().getTime() + Math.random(),
					variant: 'error',
				},
			}));
			dispatch(setErrorFlag(flagNames.UPDATE_NOTIFICATION_SETTINGS, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateProfile = (firstName, lastName, company, phone, profilePicture) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.USER_UPDATE, true));

		try {
			const store = getStore();
			const userId = _.get(store, 'auth.user._id', null);
			const token = _.get(store, 'provider.check.token', null);

			let profilePictureUrl = null;

			if (profilePicture instanceof File) {
				const {
					type,
				} = profilePicture;
				const { data } = await ProviderCRUD.getSignedUrl(`${userId}/profile.png`, type, token, "public-read");
				const {
					signedRequest,
					url
				} = data;
				profilePictureUrl = url;

				await ProviderCRUD.uploadFile(signedRequest, profilePicture);
			}
			else {
				profilePictureUrl = profilePicture;
			}

			const { status, data } = await AuthCRUD.update(
				userId,
				firstName,
				lastName,
				company,
				phone,
				profilePictureUrl
			);

			if (status === 200) {
				dispatch({
					type: actionTypes.UserUpdated,
					payload: {
						user: {
							...data
						},
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.USER_UPDATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.USER_UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.USER_UPDATE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateChatService = (chatService) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.UPDATE_CHAT_SERVICE, true));

		try {
			const store = getStore();
			const userId = _.get(store, 'auth.user._id', null);

			const { status, data } = await AuthCRUD.updateChatService(
				userId,
				chatService,
			);

			if (status === 200) {
				dispatch({
					type: actionTypes.UserUpdated,
					payload: {
						user: {
							...data
						},
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE_CHAT_SERVICE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_CHAT_SERVICE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE_CHAT_SERVICE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateTwoFactorAuthService = (twoFactorAuthService, password) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE, true));

		try {
			const store = getStore();
			const userId = _.get(store, 'auth.user._id', null);

			const { status, data } = await AuthCRUD.updateTwoFactorAuthService(
				userId,
				twoFactorAuthService,
				password,
			);

			if (status === 200) {
				await dispatch(requestLoggedInUser());
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE_TWO_FACTOR_AUTH_SERVICE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const addTwoFactorAuthEmail = (email, password) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.ADD_TWO_FACTOR_AUTH_EMAIL, true));

		try {
			const store = getStore();
			const user = _.get(store, 'auth.user', null);
			const userId = user._id;

			const { status, data } = await AuthCRUD.addTwoFactorAuthEmail(
				userId,
				email,
				password,
			);

			if (status === 200) {
				dispatch({
					type: actionTypes.UserUpdated,
					payload: {
						user: {
							...user,
							settings: data.data,
						},
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.ADD_TWO_FACTOR_AUTH_EMAIL, data.message));
			}

			dispatch(setLoadingFlag(flagNames.ADD_TWO_FACTOR_AUTH_EMAIL, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.ADD_TWO_FACTOR_AUTH_EMAIL, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const removeTwoFactorAuthEmail = (email, password) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL, email));

		try {
			const store = getStore();
			const user = _.get(store, 'auth.user', null);
			const userId = user._id;

			const { status, data } = await AuthCRUD.removeTwoFactorAuthEmail(
				userId,
				email,
				password,
			);

			if (status === 200) {
				dispatch({
					type: actionTypes.UserUpdated,
					payload: {
						user: {
							...user,
							settings: data.data,
						},
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL, data.message));
			}

			dispatch(setLoadingFlag(flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.REMOVE_TWO_FACTOR_AUTH_EMAIL, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const validateTwoFactorAuthToken = (uid, token) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.VALIDATE_TWO_FACTOR_AUTH_TOKEN, true));

		try {
			const { status, data } = await AuthCRUD.validateTwoFactorAuthToken(
				uid,
				token,
			);

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.VALIDATE_TWO_FACTOR_AUTH_TOKEN, data.message));
			}

			dispatch(setLoadingFlag(flagNames.VALIDATE_TWO_FACTOR_AUTH_TOKEN, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.VALIDATE_TWO_FACTOR_AUTH_TOKEN, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateDownloadFormat = (downloadFormat) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.UPDATE_DOWNLOAD_FORMAT, true));

		try {
			const store = getStore();
			const userId = _.get(store, 'auth.user._id', null);

			const { status, data } = await AuthCRUD.updateDownloadFormat(
				userId,
				downloadFormat,
			);

			if (status === 200) {
				dispatch({
					type: actionTypes.UserUpdated,
					payload: {
						user: {
							...data
						},
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE_DOWNLOAD_FORMAT, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_DOWNLOAD_FORMAT, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE_DOWNLOAD_FORMAT, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updatePassword = (oldPassword, newPassword) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.UPDATE_PASSWORD, true));

		try {
			const { status, data } = await AuthCRUD.updatePassword(oldPassword, newPassword);

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE_PASSWORD, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_PASSWORD, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE_PASSWORD, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateCommunicationsData = (message, logo) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.UPDATE_COMMUNICATIONS_DATA, true));

		try {
			// const { status, data } = await AuthCRUD.updatePassword(oldPassword, newPassword);
			const { status, data } = await sleep();

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE_COMMUNICATIONS_DATA, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_COMMUNICATIONS_DATA, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE_COMMUNICATIONS_DATA, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const uploadAttachmentFile = (file) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.UPLOAD_ATTACHMENT_FILE, true));

		try {
			// const { status, data } = await AuthCRUD.updatePassword(oldPassword, newPassword);
			const { status, data } = await sleep();

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.UPLOAD_ATTACHMENT_FILE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPLOAD_ATTACHMENT_FILE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPLOAD_ATTACHMENT_FILE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const deleteAttachmentFile = (attachmentId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.DELETE_ATTACHMENT_FILE, attachmentId));

		try {
			// const { status, data } = await AuthCRUD.updatePassword(oldPassword, newPassword);
			const { status, data } = await sleep(5000, 200, {
				_id: Date.now(),
			});

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.DELETE_ATTACHMENT_FILE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.DELETE_ATTACHMENT_FILE, null));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.DELETE_ATTACHMENT_FILE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const cancelSubscription = () => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.CANCEL_SUBSCRIPTION, true));

		try {
			const { status, data } = await SubscriptionCRUD.cancelSubscription();

			if (status !== 200) {
				dispatch(setErrorFlag(flagNames.CANCEL_SUBSCRIPTION, data.error.message));
			}

			dispatch(setLoadingFlag(flagNames.CANCEL_SUBSCRIPTION, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.CANCEL_SUBSCRIPTION, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const setUserRestriction = (restriction) => {
	return {
		type: actionTypes.UpdateUserRestrictions,
		payload: restriction,
	}
}

export const updateSettings = ({ layoutSettings, fileRenameSeparator }) => {
	return async (dispatch, getStore) => {
		try {
			const store = getStore();
			const requestBody = store?.auth?.user?.settings || {};

			if (layoutSettings) {
				requestBody.layoutSettings = layoutSettings;
			}
			if (typeof fileRenameSeparator === "string") {
				requestBody.fileRenameSeparator = fileRenameSeparator;
			}

			const { status, data } = await AuthCRUD.updateSettings(requestBody);

			if (status === 200) {
				dispatch({
					type: actionTypes.UpdateSettings,
					payload: {
						layoutSettings,
						fileRenameSeparator,
					}
				});
			}
			else {
				console.log('request to update settings failed', status, data);
			}

			return Promise.resolve({ status, data });
		}
		catch (e) {
			return Promise.reject(e);
		}
	}
}